import { Box, Divider, Typography } from "@mui/material";

const AuthNRSHeader = () => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100vw",
        overflowX: "hidden",
        mb: { xs: 2, md: 7 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mt: 2,
          fontSize: { xs: "1.5rem", md: "1.7rem" },
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        <span style={{ fontWeight: "600" }}>Neurorobotics</span> Studio
      </Typography>

      <Divider sx={{ borderColor: "accents.dark", my: 1 }} />

      <Typography
        color="primary"
        sx={{
          mr: "-.8rem", // offset the letter spacing ending blank
          letterSpacing: "0.5rem",
          textAlign: "center",
          textTransform: "uppercase",
          cursor: "pointer",
        }}
      >
        Brains for Robots™
        {/* <LinkMui
          href="https://www.feagi.org"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ textDecoration: "none" }}
        >
          Powered by <span style={{ fontWeight: "700" }}>FEAGI</span>
        </LinkMui> */}
      </Typography>
    </Box>
  );
};

export default AuthNRSHeader;
